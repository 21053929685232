import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { BlogCardT } from 'src/components/BlogCard/_types'
import BlogCard from 'src/components/BlogCard/_BlogCard'
import { GridContainer } from './_style'
import { H3Citrina } from 'src/components/Text/Headings'

const blogCards: BlogCardT[] = [
  {
    id: 'blog_beneficios_1',
    category: 'Cuidando do seu dinheiro',
    title: 'Promoção Indique e Ganhe Inter - Ganhe até 40.000 pontos Inter Loop',
    description: 'A Orange Friday está rolando e as condições estão imperdíveis para quem compra no ...',
    date: '03/01/2023',
    link: 'https://blog.inter.co/promocao-indique-e-ganhe/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/recompensa-blog1/image.webp',
  },
  {
    id: 'blog_beneficios_2',
    category: 'Inter Shop',
    title: 'O que é cashback e como conseguir dinheiro de volta',
    description: 'Apesar de estar em alta, muita gente ainda tem dúvidas sobre o que é cashback, quais as vantagens e como ganhar dinheiro de volta em compras.',
    date: '19/11/2020',
    link: 'https://blog.inter.co/o-que-e-cashback/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/recompensa-blog2/image.webp',
  },
  {
    id: 'blog_beneficios_3',
    category: 'Inter Bank',
    title: 'Inter Loop: tudo sobre o programa de pontos do Inter!',
    description: 'Chegou o Inter Loop! O programa de pontos do Inter gratuito e completo, em que você troca sua pontuação por dive...',
    date: '19/11/2020',
    link: 'https://blog.inter.co/inter-loop-programa-de-pontos/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/recompensa-blog3/image.webp',
  },
]

export const BlogBeneficios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <SectionComponent
      id='blog_beneficios'
      minHeight={{ sm: '1530px', md: '690px', lg: '753px', xl: '763px', xxl: '763px' }}
    >
      <H3Citrina
        fontSize={{ md: '24px', lg: '28px', xl: '40px' }}
        lineHeight={{ md: '28px', lg: '33px', xl: '44px' }}
        className='mx-auto d-block mb-4'
      >
        Tudo sobre os benefícios Inter
      </H3Citrina>
      <GridContainer>
        {blogCards.map((card: BlogCardT) => (
          <div key={card.id}>
            <BlogCard
              card={card}
              onCardClick={
              () => sendDatalayerEvent({
                section: 'dobra_06',
                section_name: 'Tudo sobre os benefícios Inter',
                element_action: 'click button',
                element_name: card.title,
                redirect_url: card.link,
              })}
            />
          </div>
      ))}
      </GridContainer>
    </SectionComponent>
  )
}
