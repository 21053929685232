import React, { useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { HeroVantagensRecompensas } from './sections/HeroVantagensRecompensas/_index'
import { InterLoop } from './sections/InterLoop/_index'
import { InterLoopEmpresas } from './sections/InterLoopEmpresas/_index'
import { MaisBeneficios } from './sections/MaisBeneficios/_index'
import { IndiqueGanhePontos } from './sections/IndiqueGanhePontos/_index'
import { BlogBeneficios } from './sections/BlogBeneficios/_index'
import { Theme, initTheme } from '@interco/inter-ui'

const Recompensas = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  })
  return (
    <Layout pageContext={pageContext}>
      <HeroVantagensRecompensas />
      <InterLoop />
      <InterLoopEmpresas />
      <MaisBeneficios />
      <IndiqueGanhePontos />
      <BlogBeneficios />
    </Layout>
  )
}

export default Recompensas
