import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const InfoBox = styled.div`
  border-radius: 14px;
  overflow: hidden;
  @media (min-width: ${breakpoints.md}) {
    max-width: 210px;
    min-height: 200px;
    display: inline-flex;
    flex-direction: column;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 194px;
    min-height: 200px;
    display: inline-flex;
    flex-direction: column;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 242px;
    min-height: 180x;
    display: inline-flex;
    flex-direction: column;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    max-width: 312px;
  }

  header, div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  header {
    background-color: ${orange.clay};
    padding: 8px 14px;
    text-align: center;
    height: 64px;
    h3 {
      font-family: Citrina, sans-serif;
      color: #fff;
      margin: 0;
    }
  }
  div {
    background-color:#fff;
    padding: 8px 16px;
    width: 100%;
    text-align: center;
    @media (min-width: ${breakpoints.md}) {
      flex-grow: 1;
    }
    @media (min-width: ${breakpoints.xl}) {
      max-height: 100px;
    }
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 24px;
`
