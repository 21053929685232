import React from 'react'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG, WIDTH_MD } from 'src/utils/breakpoints'
import { TextContent } from '../../types/_index'
import { grayscale } from 'src/styles/colors'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'

const CONTENT: TextContent = {
  heading: 'Mais benefícios, menos despesas: aproveite o cashback do Inter',
  paragraph: 'Aqui você tem um mundo de benefícios e vantagens exclusivas - e ainda pode economizar em todas as áreas! Com as recompensas do Inter, você ganha cashback em compras no Super App, gift cards, viagens, investimentos e muito mais. E o melhor: pode usar o dinheiro de volta como quiser. Quer ganhar cashback? Então vem com a gente!',
}

export const MaisBeneficios = () => {
  const width = useWidth()
  const imagePath = width >= WIDTH_MD && width < WIDTH_LG ? 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-768-recompensas/image.webp'
  : 'https://central-imagens.bancointer.com.br/images-without-small-versions/investimentos-viagens-cashback-gift/image.webp'
  return (
    <SectionComponent
      id='mais_beneficios'
      minHeight={{ sm: '505px', md: '425px', lg: '420px', xl: '582px', xxl: '582px' }}
    >
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-7 mb-4'>
          <ImageWebpWithSizes
            widths={{ sm: 305, md: 291, lg: 447, xl: 636, xxl: 636 }}
            heights={{ sm: 'auto', md: 329, lg: 'auto', xl: 'auto', xxl: 'auto' }}
            pathSrc={imagePath}
            className='mx-auto d-block'
            altDescription='Homem com celular na mão acessando o Super App do Inter'
          />
        </div>
        <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-0'>
          <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
          <InterP fontSize={{ lg: '18px' }} lineHeight={{ lg: '22px' }} color={grayscale[400]}>{CONTENT.paragraph}</InterP>
        </div>
      </div>
    </SectionComponent>
  )
}
