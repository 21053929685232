import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const CardContainer = styled.div`
  background-color: ${grayscale[100]};
  padding: 24px;
  border-radius: 16px;
  max-width: 288px;
  max-height: 210px;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    max-width: 100%;
    max-height: 100%;
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 24px 0;
  ${CardContainer}:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
  ${CardContainer}:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
  ${CardContainer}:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
  ${CardContainer}:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
  ${CardContainer}:nth-child(5) { grid-area: 3 / 1 / 4 / 3; }

  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 24px;

    ${CardContainer}:nth-child(1) {
      display: flex;
      max-height: 208px;
      grid-column: 1 / 4;
    }
    ${CardContainer}:nth-child(2) {
      display: flex;
      max-height: 208px;
      grid-column: 4 / 7;
    }
    ${CardContainer}:nth-child(3) {
      grid-column: 1 / 3;
      grid-row: 2 / 2;
    }
    ${CardContainer}:nth-child(4) {
      grid-column: 3 / 5;
      grid-row: 2 / 2;
    }
    ${CardContainer}:nth-child(5) {
      grid-column: 5 / 7;
      grid-row: 2 / 2;
    }
  }
`
