import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { grayscale } from 'src/styles/colors'
import { TextContent } from '../../types/_index'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { WIDTH_LG, WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import { ButtonLink } from 'src/components/ButtonLink'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'

const CONTENT: TextContent = {
  heading: 'Indique e ganhe até 40.000 pontos no Inter Loop',
  paragraph: 'Convide seus amigos para simplificar os dias com a nossa conta digital gratuita e ganhe muitos pontos no Inter Loop para trocar pelo que quiser. ',
  extra_paragraph: 'Funciona assim: para cada amigo indicado que abrir conta no Inter você ganhará 100 pontos Inter Loop. E tem mais, o prêmio é cumulativo e pode chegar até 40.000 pontos convidando amigos para abrir conta aqui',
  cta: 'Saiba mais sobre como participar',
}

export const IndiqueGanhePontos = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const imagePath = width >= WIDTH_MD && width < WIDTH_LG ? 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-768-recompensa/image.webp'
  : 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-web-recompensa/image.webp'
  return (
    <SectionComponent
      id='indique_ganhe_pontos'
      bgColor={grayscale[100]}
      minHeight={{ sm: '516px', md: '500px', lg: '502px', xl: '649px', xxl: '649px' }}
    >
      <div className='row w-100'>
        <div className='col-md-6 col-lg-5'>
          <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
          <InterP fontSize={{ sm: '18px', lg: '18px' }} lineHeight={{ sm: '21px', lg: '22px' }} color={grayscale[400]} className='mb-3'>{CONTENT.paragraph}</InterP>
          <InterP fontSize={{ sm: '18px', lg: '18px' }} lineHeight={{ sm: '21px', lg: '22px' }} color={grayscale[400]} className='mb-3'>{CONTENT.extra_paragraph}</InterP>
          <ButtonLink
            className='mx-auto d-block'
            target='blank'
            rel='noopener'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_05',
                element_action: 'click button',
                section_name: CONTENT.heading,
                element_name: CONTENT.cta ?? '',
                redirect_url: 'https://inter.co/indique-e-ganhe/',
              })
            } }
            width='100%'
            height='48px'
            href='https://inter.co/indique-e-ganhe/'
          >{CONTENT.cta}
          </ButtonLink>
        </div>
        {width >= WIDTH_MD && (
          <div className='col-md-6 col-lg-5 offset-lg-1'>
            <ImageWebpWithSizes
              pathSrc={imagePath}
              className='mx-auto d-block'
              altDescription='Grupo de amigos em uma mesa de restaurante. Um deles está com um celular na mão'
              widths={{ md: 303, lg: 355, xl: 457, xxl: 457, xxxl: 457 }}
              heights={{ md: 403, lg: 355, xl: 457, xxl: 457, xxxl: 457 }}
            />
          </div>
        )}
      </div>
    </SectionComponent>

  )
}
