import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { TextContent } from '../../types/_index'
import { ImageWrapper, SectionWrapper } from './_styles'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import ScrollTo from 'src/components/ScrollTo'
import { H1Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'

const CONTENT: TextContent = {
  heading: 'Vantagens e recompensas especiais para você',
  paragraph: 'Tenha uma vida financeira com muito mais vantagens no Inter. Aqui, você tem acesso a recompensas exclusivas, como o nosso programa de pontos Inter Loop, Indique e Ganhe e cashback direto na conta. Vem ficar por dentro!',
  cta: 'Conhecer benefícios',
}

export const HeroVantagensRecompensas = () => {
  const width = useWidth()
  return (
    <div style={{ position: 'relative' }}>
      {width >= WIDTH_MD && (
        <ImageWrapper>
          <ImageWebpWithSizes
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/recompensa-hero/image.webp'
            altDescription='Homem usando roupas laranjas, sentado, sorrindo e segurando um cartão Inter'
          />
        </ImageWrapper>
      )}
      <SectionWrapper>
        <SectionComponent
          id='hero_vantagens_recompensas'
          ContainerStyles='my-auto'
          minHeight={{ sm: '452px', md: '358px', lg: '421px', xl: '591px', xxl: '591px' }}
        >
          <div className='col-12 col-md-6 offset-md-6 col-lg-5 offset-lg-7'>
            <H1Citrina className='mb-3' color='#fff'>{CONTENT.heading}</H1Citrina>
            <InterP className='mb-3 mt-lg-2' color='#fff'>{CONTENT.paragraph}</InterP>
            <ScrollTo
              to='#inter_loop'
              section='hero_vantagens_recompensas'
              sectionName={CONTENT.heading ?? ''}
              elementName={CONTENT.cta ?? ''}
            >{CONTENT.cta}
            </ScrollTo>
          </div>
        </SectionComponent>
      </SectionWrapper>
    </div>
  )
}
