/* eslint-disable @typescript-eslint/typedef */
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ButtonLink = styled.a<{outline?: boolean; width?: string; height?: string}>`
  width: ${props => props.width ?? 'inherit'};
  height: ${props => props.height ?? '40px'};
  background-color: ${props => props.outline ? 'transparent' : orange.extra};
  border: ${props => props.outline ? `1px solid ${orange.extra}` : 'none'};
  color: ${props => props.outline ? orange.extra : '#fff' };
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.outline ? '#FFF2E7' : '#FF9D42 '};
    color: ${props => props.outline ? orange.extra : '#fff '};
  }
`
