import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { TextContent } from '../../types/_index'
import { grayscale } from 'src/styles/colors'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { primary } from 'src/styles/newColors'
import { WIDTH_LG, WIDTH_SM } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import { CardContainer, GridContainer, TextContainer } from './_style'
import { ButtonLink } from 'src/components/ButtonLink'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { H2Citrina, H3Sora } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'

const CONTENT: TextContent = {
  heading: 'Inter Loop: Programa de pontos e benefícios Inter',
  paragraph: 'Ganhe pontos para trocar por milhas, desconto na fatura do cartão, cashback extra em compras no shopping do Inter e até por investimentos. Use seu cartão Inter Mastercard, acumule pontos e escolha os benefícios que mais combinam com você',
  cta: 'Descubra mais vantagens',
}

type CarouselItem = {
  title: string;
  body: string;
  id: string;
};

const carouselItems: CarouselItem[] = [
  {
    id: 'inter_loop_1',
    title: 'Meu porquinho',
    body: 'Transforme seus pontos em investimento e veja seu dinheiro render.',
  },
  {
    id: 'inter_loop_2',
    title: 'Desconto na fatura',
    body: 'Converta seus pontos em desconto na próxima fatura do seu cartão de crédito e economize dinheiro.',
  },
  {
    id: 'inter_loop_3',
    title: 'Cashback direto na conta',
    body: 'É o cashback Inter que você já conhece: troque os pontos por dinheiro de volta direto na sua conta digital.',
  },
  {
    id: 'inter_loop_4',
    title: 'Pontos no programa de fidelidade da Azul',
    body: 'Transfira seus pontos para o programa da Azul Linhas Aéreas e viva experiências incríveis pelo mundo.',
  },
  {
    id: 'inter_loop_5',
    title: 'Compras com cashback extra',
    body: 'Compre no shopping do Inter e faça seus pontos virarem desconto em mais de 270 lojas, como Casas Bahia, Fast Shop, Centauro e muito mais.',
  },
]

export const InterLoop = () => {
  const width = useWidth()
  const btnWidth = width <= WIDTH_SM ? '100%' : '452px'
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <SectionComponent
      id='inter_loop'
      minHeight={{ sm: '626px', md: '810px', lg: '778px', xl: '923px', xxl: '923px' }}
    >
      <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
      <InterP color={grayscale[400]}>{CONTENT.paragraph}</InterP>
      {width <= WIDTH_SM && (
        <InterLoop.SMLayout />
      )}
      {width > WIDTH_SM && width < WIDTH_LG && (
        <InterLoop.MDLayout />
      )}
      {width >= WIDTH_LG && (
        <InterLoop.LGLayout />
      )}
      <ButtonLink
        className='mx-auto d-block'
        target='blank'
        rel='noopener'
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_02',
            element_action: 'click button',
            section_name: CONTENT.heading,
            element_name: CONTENT.cta ?? '',
            redirect_url: 'https://inter.co/pra-voce/cartoes/programa-de-pontos/',
          })
        }}
        width={btnWidth}
        height='48px'
        href='https://inter.co/pra-voce/cartoes/programa-de-pontos/'
      >{CONTENT.cta}
      </ButtonLink>
    </SectionComponent>
  )
}

InterLoop.SMLayout = () => (
  <div className='col-12'>
    <DefaultCarousel
      containerClass='mb-3'
      customDotColor={primary[500]}
      sm={{ items: 1, partialVisibilityGutter: 20 }}
    >
      {carouselItems.map((item: CarouselItem) => (
        <CardContainer key={item.id}>
          <H3Sora className='mb-3'>{item.title}</H3Sora>
          <InterP color={grayscale[400]}>{item.body}</InterP>
        </CardContainer>
        ))}
    </DefaultCarousel>
  </div>
)

InterLoop.MDLayout = () => (
  <GridContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[1].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[1].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[0].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[0].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[2].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[2].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[3].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[3].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[4].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[4].body}</InterP>
    </CardContainer>
  </GridContainer>
)

InterLoop.LGLayout = () => (
  <GridContainer>
    <CardContainer>
      <TextContainer>
        <H3Sora className='mb-3'>{carouselItems[1].title}</H3Sora>
        <InterP color={grayscale[400]}>{carouselItems[1].body}</InterP>
      </TextContainer>
      <ImageWebpWithSizes
        pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-mockup/image.webp'
        altDescription=''
        aria-hidden='true'
        style={{ transform: 'rotate(18deg)' }}
        widths={{ lg: 'auto', xxl: 'auto', xxxl: 'auto', xl: 'auto' }}
        heights={{ lg: 'auto', xxl: 'auto', xxxl: 'auto', xl: 'auto' }}
      />
    </CardContainer>
    <CardContainer>
      <TextContainer>
        <H3Sora className='mb-3'>{carouselItems[0].title}</H3Sora>
        <InterP color={grayscale[400]}>{carouselItems[0].body}</InterP>
      </TextContainer>
      <ImageWebpWithSizes
        pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/poquinho-do-inter/image.webp'
        altDescription=''
        aria-hidden='true'
        widths={{ lg: 'auto', xxl: 'auto', xxxl: 'auto', xl: 'auto' }}
        heights={{ lg: 'auto', xxl: 'auto', xxxl: 'auto', xl: 'auto' }}
      />
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[2].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[2].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[3].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[3].body}</InterP>
    </CardContainer>
    <CardContainer>
      <H3Sora className='mb-3'>{carouselItems[4].title}</H3Sora>
      <InterP color={grayscale[400]}>{carouselItems[4].body}</InterP>
    </CardContainer>
  </GridContainer>
)
